import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"service"} />
      <Helmet>
        <title>
          Our Services | Navigate Our Services at Rubber Revolution Tires
        </title>
        <meta
          name={"description"}
          content={"Elevating Your Drive, One Service at a Time"}
        />
        <meta
          property={"og:title"}
          content={
            "Our Services | Navigate Our Services at Rubber Revolution Tires"
          }
        />
        <meta
          property={"og:description"}
          content={"Elevating Your Drive, One Service at a Time"}
        />
        <meta
          property={"og:image"}
          content={"https://plexiastark.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://plexiastark.com/img/2761318.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://plexiastark.com/img/2761318.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="140px 0"
        sm-padding="40px 0 40px 0"
        background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://plexiastark.com/img/5.jpg) 50% 15%/cover"
        color="--light"
        font="--base"
      >
        <Override slot="SectionContent" sm-align-items="center" />
        <Box width="100%">
          <Text
            as="h1"
            margin="0 0 96px 0"
            text-align="center"
            letter-spacing="50px"
            text-transform="uppercase"
            font="100 132px/1.2 --fontFamily-sans"
            lg-font="200 42px/1.2 --fontFamily-sans"
            lg-letter-spacing="20px"
          >
            Our Services
          </Text>
        </Box>
        <Box
          display="flex"
          margin="-16px -16px -16px -16px"
          flex-wrap="wrap"
          width="100%"
        >
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="25%"
            lg-width="50%"
            sm-width="100%"
          >
            <Box display="flex" font="--lead">
              <Text margin="0px">Our Commitment to Your Journey</Text>
            </Box>
          </Box>
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="25%"
            lg-width="50%"
            sm-width="100%"
          >
            <Box display="flex">
              <Text margin="0px">
                At Rubber Revolution Tires, we're more than just a tire shop –
                we're your all-in-one solution for tire selection, maintenance,
                and care. Our services are designed to enhance your driving
                experience, ensuring safety, efficiency, and peace of mind on
                every journey. Explore our comprehensive range of services and
                find out how we can support your driving adventures.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="60px 0" sm-padding="40px 0" color="--dark">
        <Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
          <Box padding="16px 16px 16px 16px" width="100%">
            <Box display="flex" flex-direction="row" flex-wrap="wrap">
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Tire Selection and Installation
                  </Text>
                  <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                    Personalized Consultations: Our experts assess your driving
                    needs to recommend the best tires for your vehicle and
                    lifestyle.
                    <br />
                    <br />
                    Precision Installation: State-of-the-art equipment and
                    trained technicians ensure your tires are installed with
                    meticulous care.
                  </Text>
                </Box>
              </Box>
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Tire Maintenance and Care
                  </Text>
                </Box>
                <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                  Pressure Checks and Adjustments: Regular pressure checks to
                  maximize tire lifespan and fuel efficiency.
                  <br />
                  <br />
                  Rotation Services: Tire rotations to promote even wear and
                  extend tire life.
                </Text>
              </Box>
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Safety and Performance
                  </Text>
                  <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                    Alignment Checks: Proper alignment to improve handling and
                    prevent uneven tire wear.
                    <br />
                    <br />
                    Brake Services: Comprehensive brake checks and maintenance
                    for optimal safety and performance.
                  </Text>
                </Box>
              </Box>
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Specialty Tires
                  </Text>
                  <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                    All-Season and Weather-Specific Tires: Tires for every
                    season and climate, ensuring you're prepared for any road
                    condition.
                    <br />
                    <br />
                    Performance Tires: Enhance your vehicle's handling and
                    responsiveness with our selection of high-performance tires.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://plexiastark.com/img/6.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Let's Get Rolling
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Interested in learning more about how Rubber Revolution Tires can
            elevate your driving experience? Our team is on hand to provide
            detailed information on our services and how we can tailor them to
            meet your specific needs. Visit us or reach out today to discover
            the perfect service package for your vehicle. Your journey towards
            better driving starts here.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
